<template>
    <main id="liste" v-cloak>
        <!-- A changer par le nom du tiers sur lequel on effectue les opérations de compta -->
        <HeaderTab :title="$t('payment.payment')" :swiper_tabs="swiper_tabs" />

        <div id="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div>
                            <router-view></router-view>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>


<script type="text/javascript">
    import Navigation from "@/mixins/Navigation.js";

	export default {
		name: "Payment",
		mixins: [Navigation],
		data () {
			return {
                param_tab: '',
                actual_tab: 'paymentList',

                
			}
		},

		mounted() {
            this.param_tab = this.$route.params.actual_tab
            this.init_component()
		},

		methods: {
            async init_component() {
                // CHECK ICI SI this.$route.params.tiers_id
                // est valide et tout et tout
            }
		},
        computed: {
            hasMultiComptaAccess() {
                return this.$store.state.userAccess.hasMultiComptaAccess
            },
            swiper_tabs(){
                return [{
                        id: 'list',
						label: 'payment.list',
						active: false,
						href : 'paymentList',
					}, {
                        id: 'checkDeposit',
                        label: 'check.check_deposit',
                        active: false,
                        href : 'checkDeposit',
                    }, {
                        id: 'regulBancaire',
                        label: 'payment.regul_compta',
                        active: false,
                        hidden: !this.hasMultiComptaAccess,
                        href : 'regulBancaire',
                    }
				]
            }
        },
		components: {
            HeaderTab: () => import('@/components/HeaderTab'),
		}
	}
</script>
